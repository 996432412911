<template>
  <t-dropdown
    :classes="{
      dropdown: 'bg-white-900 shadow-xl w-max right-0 origin-top-right',
    }"
  >
    <button
      class="flex items-center group"
      slot="trigger"
      slot-scope="{ mousedownHandler, blurHandler, keydownHandler }"
      @mousedown="mousedownHandler"
      @blur="blurHandler"
      @keydown="keydownHandler"
    >
      <div class="flex flex-col mr-3">
        <span
          class="
            text-base
            font-medium
            text-black-900
            tracking-tight
            leading-none
            mb-1
            group-hover:text-grey-700
          "
          >{{ user.name }}</span
        >
        <span
          class="
            text-sm
            tracking-tight
            text-grey-700
            font-medium
            uppercase
            leading-none
          "
          >{{ user.organizationName }}</span
        >
      </div>
      <svg
        class="flex-shrink-0 group-hover:text-grey-700"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 7L8 11L12 7"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <ul class="py-1" slot-scope="{ hide }">
      <li>
        <button
          class="
            w-full
            flex
            items-center
            py-1.5
            px-3
            text-black-900 text-base
            hover:bg-grey-400
          "
          @click="
            () => {
              hide();
              $modal.show('update-profile-modal');
            }
          "
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.8867 5.23879L5.58141 15.5441L5.21748 19.2768L9.09084 19.0145L19.3767 8.72873L15.8867 5.23879ZM16.6608 3.69068C16.2333 3.26318 15.5402 3.26318 15.1127 3.69068L4.28163 14.5217C4.10229 14.7011 3.99078 14.9371 3.96617 15.1895L3.52054 19.7603C3.45491 20.4334 4.00925 21.0043 4.68399 20.9587L9.40662 20.6389C9.67078 20.6211 9.91951 20.508 10.1067 20.3208L20.9248 9.50279C21.3523 9.07529 21.3523 8.38218 20.9248 7.95468L16.6608 3.69068Z"
              fill="#212022"
            />
          </svg>
          <span class="ml-4">Edit profile</span>
        </button>
      </li>
      <li>
        <button
          class="
            flex
            items-center
            py-1.5
            px-3
            text-black-900 text-base
            hover:bg-grey-400
          "
          @click="
            () => {
              hide();
              $modal.show('change-password-modal');
            }
          "
        >
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 203.096 203.096"
            style="enable-background: new 0 0 203.096 203.096"
            xml:space="preserve"
          >
            <g>
              <path
                d="M153.976,73.236h-3.308V49.115C150.669,22.033,128.634,0,101.549,0C74.465,0,52.43,22.033,52.43,49.115v24.121H49.12
		c-9.649,0-17.5,7.851-17.5,17.5v94.859c0,9.649,7.851,17.5,17.5,17.5h104.856c9.649,0,17.5-7.851,17.5-17.5V90.736
		C171.476,81.087,163.626,73.236,153.976,73.236z M67.43,49.115C67.43,30.304,82.736,15,101.549,15
		c18.813,0,34.119,15.304,34.119,34.115v24.121H67.43V49.115z M156.476,185.596c0,1.355-1.145,2.5-2.5,2.5H49.12
		c-1.355,0-2.5-1.145-2.5-2.5V90.736c0-1.355,1.145-2.5,2.5-2.5H59.93h83.238h10.808c1.355,0,2.5,1.145,2.5,2.5V185.596z"
              />
              <path
                d="M101.547,116.309c-4.142,0-7.5,3.357-7.5,7.5v28.715c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-28.715
		C109.047,119.666,105.689,116.309,101.547,116.309z"
              />
            </g>
          </svg>
          <span class="ml-4">Change password</span>
        </button>
      </li>
      <li>
        <a
          href="/logout"
          @click.prevent="logout"
          class="
            flex
            items-center
            py-1.5
            px-3
            text-black-900 text-base
            hover:bg-grey-400
          "
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9668 4.69434H5.49168C5.0332 4.69434 4.66113 5.06645 4.66113 5.52488V18.4751C4.66113 18.9336 5.03324 19.3056 5.49168 19.3056H11.9668C12.4261 19.3056 12.7973 19.6769 12.7973 20.1362C12.7973 20.5955 12.4261 20.9668 11.9668 20.9668H5.49168C4.11793 20.9668 3 19.8488 3 18.4751V5.52492C3 4.15117 4.11793 3.03324 5.49168 3.03324H11.9668C12.4261 3.03324 12.7973 3.40449 12.7973 3.86379C12.7973 4.32309 12.4261 4.69434 11.9668 4.69434Z"
              fill="#212022"
            />
            <path
              d="M20.7525 12.591L16.7027 17.5744C16.3771 17.8966 15.8505 17.8924 15.5282 17.566C15.206 17.2396 15.2093 16.7139 15.5366 16.3916L18.1454 12.8302H10.4751C10.0158 12.8302 9.64453 12.4589 9.64453 11.9996C9.64453 11.5403 10.0158 11.169 10.4751 11.169H18.1454L15.5366 7.6076C15.2093 7.28534 15.2068 6.7596 15.5282 6.43319C15.691 6.26874 15.9053 6.18569 16.1196 6.18569C16.3306 6.18569 16.5415 6.26542 16.7027 6.42491L20.7525 11.4083C20.9103 11.5644 21 11.7771 21 11.9997C21 12.2222 20.9111 12.434 20.7525 12.591Z"
              fill="#212022"
            />
          </svg>

          <span class="ml-4">Log out</span>
        </a>
      </li>
    </ul>
  </t-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

import { LOGOUT } from "@/store/actions.type";

export default {
  name: "UserActions",
  methods: {
    async logout() {
      await this.$store.dispatch(LOGOUT);
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
