<template>
  <t-modal
    name="shortlist-modal"
    noBody
    variant="grey"
    :focusOnOpen="false"
    @before-open="onBeforeOpen"
  >
    <component :is="currentTabComponent" :params="params"></component>
  </t-modal>
</template>

<script>
export default {
  name: "ShortlistModal",
  components: {
    "add-shortlist-modal": () =>
      import("@/components/modals/AddShortlistModal.vue"),
    "create-shortlist-modal": () =>
      import("@/components/modals/CreateShortlist.vue"),
    "rename-shortlist-modal": () =>
      import("@/components/modals/RenameShortlist.vue"),
  },
  data() {
    return {
      currentTabComponent: "",
      params: {},
    };
  },
  methods: {
    onBeforeOpen({ params }) {
      this.currentTabComponent = params.component;
      this.params = params;
    },
  },
};
</script>
