<template>
  <footer class="bg-grey-400">
    <div
      class="
        container
        py-4
        mx-auto
        flex
        items-center
        justify-between
        space-x-20
      "
    >
      <div class="flex">
        <base-logo class="mr-6" />
        <p class="pt-2 max-w-212 text-black-600 text-sm">
          The Singapore BookData is the National Database of Titles Published in
          Singapore
        </p>
      </div>

      <nav>
        <ul class="flex items-center flex-wrap">
          <li v-for="(nav, i) in navigation" :key="i" class="w-1/3">
            <router-link
              :to="nav.url"
              class="
                text-sm text-black-900
                font-medium
                tracking-tight
                hover:text-orange-900
              "
              active-class="text-orange-900"
              :exact="nav.exact"
            >
              {{ nav.name }}
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="pt-2 text-black-600 self-start text-sm space-y-1">
        <p>Copyright: Singapore Book</p>
        <p>Publisher Association {{ new Date().getFullYear() }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
import BaseLogo from "@/components/base/BaseLogo.vue";

export default {
  name: "Footer",
  components: {
    "base-logo": BaseLogo,
  },
  data() {
    return {
      navigation: [
        { name: "Home", url: "/", exact: true },
        { name: "Publishers", url: "/publishers", exact: false },
        { name: "Catalogs", url: "/catalogs", exact: false },
        { name: "Short Lists", url: "/shortlist", exact: false },
        { name: "Contact Us", url: "/contact-us", exact: false },
        { name: "Terms", url: "/terms", exact: false },
      ],
    };
  },
};
</script>

<style></style>
