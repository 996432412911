<template>
  <t-modal
    name="search-modal"
    noBody
    :focusOnOpen="false"
    :classes="{
      wrapper: 'my-3 max-w-980 w-full',
      overlay: 'bg-grey-opacity',
    }"
  >
    <div class="bg-white-900 rounded">
      <!-- input part -->
      <div class="flex items-center px-7 py-4">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7556 20.5783L16.0682 14.8908C17.1699 13.53 17.8332 11.8009 17.8332 9.91758C17.8332 5.55262 14.2815 2.00098 9.91656 2.00098C5.55161 2.00098 2 5.55258 2 9.91754C2 14.2825 5.55165 17.8341 9.9166 17.8341C11.7999 17.8341 13.5291 17.1708 14.8899 16.0692L20.5773 21.7566C20.7398 21.9191 20.9531 22.0008 21.1665 22.0008C21.3798 22.0008 21.5932 21.9191 21.7557 21.7566C22.0815 21.4308 22.0815 20.9041 21.7556 20.5783ZM9.9166 16.1675C6.46996 16.1675 3.66666 13.3642 3.66666 9.91754C3.66666 6.47089 6.46996 3.6676 9.9166 3.6676C13.3632 3.6676 16.1665 6.47089 16.1665 9.91754C16.1665 13.3642 13.3632 16.1675 9.9166 16.1675Z"
            fill="#212022"
          />
        </svg>
        <input
          class="mx-4 flex-grow placeholder-grey-700 placeholder text-base"
          type="text"
          placeholder="Global search"
          :value="searchValue"
          @input="($event) => handleSearchValueChange($event.target.value)"
          @keydown.enter="handleSearch"
        />
        <button
          type="button"
          class="mr-8 text-grey-700 font-medium text-base"
          :class="{ invisible: !searchValue }"
          @click="() => handleSearchValueChange('')"
        >
          Clear
        </button>
        <button type="button" @click="$modal.hide('search-modal')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8569 19.8569L11.9997 11.9997M11.9997 11.9997L19.8569 4.14258M11.9997 11.9997L4.14258 19.8569M11.9997 11.9997L4.14258 4.14258"
              stroke="#212022"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>

      <!-- results part -->
      <div
        v-show="isAuthenticated && searchValue"
        class="border border-t border-grey-500"
      >
        <div
          v-if="
            searchValue.length < 3 ||
            (recommendation.books.length === 0 &&
              recommendation.catalogs.length === 0)
          "
          class="py-8 px-4 text-center text-black-600 text-base"
        >
          <p>No results were found for your search.</p>
          <p>Refine your request</p>
        </div>
        <div v-else class="pt-1 pb-2">
          <!-- catalos array -->
          <div v-if="recommendation.catalogs.length > 0">
            <div class="flex items-center py-3 px-6">
              <svg
                class="mr-1.5"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 -3.27835e-08C11.6642 -1.46777e-08 12 0.335786 12 0.75L12 6.75C12 7.16421 11.6642 7.5 11.25 7.5L5.25 7.5C4.83579 7.5 4.5 7.16421 4.5 6.75L4.5 0.75C4.5 0.335786 4.83579 -3.13158e-07 5.25 -2.95052e-07L11.25 -3.27835e-08Z"
                  fill="#A0A0A4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.25 9C10.25 9.41421 9.91421 9.75 9.5 9.75L3 9.75C2.58579 9.75 2.25 9.41421 2.25 9L2.25 2.5C2.25 2.08579 2.58579 1.75 3 1.75C3.41421 1.75 3.75 2.08579 3.75 2.5L3.75 8.25L9.5 8.25C9.91421 8.25 10.25 8.58579 10.25 9Z"
                  fill="#A0A0A4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 11.25C8 11.6642 7.66421 12 7.25 12L0.75 12C0.335786 12 -3.35013e-07 11.6642 -3.16908e-07 11.25L-3.27835e-08 4.75C-1.46777e-08 4.33579 0.335786 4 0.75 4C1.16421 4 1.5 4.33579 1.5 4.75L1.5 10.5L7.25 10.5C7.66421 10.5 8 10.8358 8 11.25Z"
                  fill="#A0A0A4"
                />
              </svg>
              <h5 class="text-grey-700 text-sm font-medium uppercase">
                Catalogs
              </h5>
            </div>
            <ul>
              <li>
                <router-link
                  v-for="item in recommendation.catalogs"
                  :key="item.id"
                  :to="{ name: 'catalog', params: { handle: item.handle } }"
                  class="
                    flex
                    items-start
                    cursor-pointer
                    py-1.5
                    px-6
                    hover:bg-grey-400
                  "
                >
                  <img
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                    :alt="item.name"
                    class="filter drop-shadow-lg mr-4"
                    width="35"
                    height="auto"
                  />
                  <img
                    v-else
                    src="@/assets/no-image.png"
                    class="filter drop-shadow-lg mr-4"
                    width="35"
                    height="auto"
                  />
                  <div class="flex-grow">
                    <h4
                      class="text-black-900 text-sm font-medium mb-2 capitalize"
                    >
                      {{ item.name }}
                    </h4>
                    <span
                      class="
                        flex
                        items-center
                        text-grey-700 text-sm
                        uppercase
                        font-medium
                      "
                    >
                      <svg
                        class="mr-1.5"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
                          fill="currentColor"
                        />
                      </svg>

                      {{ item.bookCount }} titles
                    </span>
                    <!-- <h5
                      v-else
                      class="
                        text-grey-700 text-sm
                        uppercase
                        font-medium
                        line-clamp-2
                      "
                    >
                      {{ item.author }}
                    </h5> -->
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div v-if="recommendation.books.length > 0">
            <div class="flex items-center py-3 px-6">
              <svg
                class="mr-1.5"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
                    fill="#A0A0A4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h5 class="text-grey-700 text-sm font-medium uppercase">Books</h5>
            </div>

            <ul>
              <li
                v-for="item in recommendation.books"
                :key="item.id"
                :to="`/books/${item.handle}`"
                class="
                  flex
                  items-start
                  cursor-pointer
                  py-1.5
                  px-6
                  hover:bg-grey-400
                "
                @click="openBookModal(item)"
              >
                <img
                  v-if="item.imageUrl"
                  :src="item.imageUrl"
                  :alt="item.title"
                  class="filter drop-shadow-lg mr-4"
                  width="35"
                  height="auto"
                />
                <img
                  v-else
                  src="@/assets/no-image.png"
                  class="filter drop-shadow-lg mr-4"
                  width="35"
                  height="auto"
                />
                <div class="flex-grow">
                  <h4
                    class="text-black-900 text-sm font-medium mb-2 capitalize"
                  >
                    {{ item.title }}
                  </h4>
                  <h5
                    class="
                      text-grey-700 text-sm
                      uppercase
                      font-medium
                      line-clamp-2
                    "
                  >
                    {{ item.author ? item.author.join(", ") : "" }}
                  </h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </t-modal>
</template>

<script>
import { mapGetters } from "vuex";

import {
  FETCH_RECOMMENDATION,
  UPDATE_BOOK_BASE_PAGE,
} from "@/store/actions.type";

export default {
  name: "SearchModal",
  data() {
    return {
      searchValue: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "recommendation"]),
  },
  methods: {
    handleSearchValueChange(event) {
      this.searchValue = event;
      if (this.isAuthenticated && this.searchValue.trim().length >= 3) {
        this.$store.dispatch(FETCH_RECOMMENDATION, {
          q: this.searchValue.trim(),
        });
      }
    },
    handleSearch() {
      if (this.searchValue.trim().length >= 3) {
        this.$router.push({ name: "search", query: { q: this.searchValue } });
      }
    },
    openBookModal(bookData) {
      this.$store.dispatch(UPDATE_BOOK_BASE_PAGE, {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      });
      this.$router.push({ name: "book", params: { handle: bookData.handle } });
    },
  },
};
</script>
