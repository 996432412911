<template>
  <header class="bg-white-900 fixed inset-x-0 top-0 z-20">
    <div
      class="container py-4 mx-auto flex items-center justify-between space-x-6"
    >
      <base-logo />

      <nav class="flex-grow">
        <ul class="flex items-center justify-center space-x-16">
          <li v-for="(nav, i) in navigation" :key="i">
            <!-- TODO: check for before npm -->
            <router-link
              :to="nav.url"
              class="
                text-base text-black-900
                font-medium
                tracking-tight
                hover:text-orange-900
              "
              active-class="text-orange-900 relative before:empty-content before:w-1 before:h-1 before:bg-orange-900 before:absolute before:left-1/2 before:transform before:-translate-x-1/2 before:top-full before:rounded-full before:mt-1"
              :exact="nav.exact"
            >
              {{ nav.name }}
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="flex items-center space-x-6">
        <button @click="$modal.show('search-modal')">
          <!-- temp -->
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.7556 18.5783L14.0682 12.8908C15.1699 11.53 15.8332 9.80088 15.8332 7.91758C15.8332 3.55262 12.2815 0.000976562 7.91656 0.000976562C3.55161 0.000976562 0 3.55258 0 7.91754C0 12.2825 3.55165 15.8341 7.9166 15.8341C9.7999 15.8341 11.5291 15.1708 12.8899 14.0692L18.5773 19.7566C18.7398 19.9191 18.9531 20.0008 19.1665 20.0008C19.3798 20.0008 19.5932 19.9191 19.7557 19.7566C20.0815 19.4308 20.0815 18.9041 19.7556 18.5783ZM7.9166 14.1675C4.46996 14.1675 1.66666 11.3642 1.66666 7.91754C1.66666 4.47089 4.46996 1.6676 7.9166 1.6676C11.3632 1.6676 14.1665 4.47089 14.1665 7.91754C14.1665 11.3642 11.3632 14.1675 7.9166 14.1675Z"
              fill="#212022"
            />
          </svg>
        </button>

        <router-link class="relative" to="/shortlist">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.5523 9.5043L12 4.3331L9.44772 9.5043L3.74112 10.3336L7.87038 14.3588L6.89574 20.0425L12.0001 17.3589L17.1041 20.0424L16.1292 14.3588L20.2588 10.3336L14.5523 9.5043ZM1.43235 10.1777L6.25868 14.8825L5.1195 21.5257C5.07891 21.7622 5.17615 22.0011 5.37026 22.1422C5.48007 22.222 5.61013 22.2626 5.74082 22.2626C5.84118 22.2626 5.94187 22.2387 6.03407 22.1902L12 19.0536L17.9658 22.1901C18.1782 22.3018 18.4355 22.2832 18.6296 22.1422C18.8237 22.0011 18.921 21.7621 18.8805 21.5256L17.7409 14.8825L22.5678 10.1777C22.7396 10.0103 22.8015 9.75978 22.7273 9.53159C22.6531 9.30336 22.4559 9.13703 22.2185 9.10257L15.5484 8.13329L12.5653 2.08915C12.4591 1.87399 12.24 1.73779 12.0001 1.73779C11.7601 1.73779 11.541 1.87399 11.4348 2.08915L8.45165 8.13329L1.78165 9.10257C1.54426 9.13703 1.34695 9.30336 1.27283 9.53154C1.19865 9.75978 1.26051 10.0103 1.43235 10.1777Z"
              fill="#212022"
            />
          </svg>

          <span
            v-if="isAuthenticated"
            class="
              absolute
              -right-2
              -top-1
              flex
              items-center
              justify-center
              bg-orange-900
              rounded-full
              text-white-900 text-xxs
              font-medium
              leading-none
              w-4
              h-4
            "
            >{{ user.shortlistCount }}</span
          >
        </router-link>
      </div>

      <user-actions v-if="isAuthenticated" />
      <template v-else>
        <router-link
          :to="{ name: 'login', query: { redirect: $route.path } }"
          class="
            text-base text-black-900
            font-medium
            tracking-tight
            leading-none
            hover:text-orange-900
          "
          >Sign In</router-link
        >
        <router-link
          :to="{ name: 'joinUs' }"
          class="
            text-base text-black-900
            font-medium
            tracking-tight
            leading-none
            hover:text-orange-900
          "
          >Join Us</router-link
        >
      </template>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

import BaseLogo from "@/components/base/BaseLogo.vue";
import UserActions from "@/components/base/UserActions.vue";

export default {
  name: "Header",
  components: {
    "base-logo": BaseLogo,
    "user-actions": UserActions,
  },
  data() {
    return {
      navigation: [
        { name: "Home", url: "/", exact: true },
        { name: "Publishers", url: "/publishers", exact: false },
        { name: "Catalogs", url: "/catalogs", exact: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
  },
};
</script>

<style></style>
