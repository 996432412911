<template>
  <t-modal
    name="update-profile-modal"
    noBody
    variant="grey"
    :focusOnOpen="false"
    @before-open="onBeforeOpen"
  >
    <div class="bg-white-900 rounded px-5 pt-6 pb-4 relative">
      <div class="flex justify-between items-center">
        <h3 class="text-black-900 font-medium text-2xl mr-2">Update Profile</h3>
        <button @click="$modal.hide('update-profile-modal')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8574 19.8569L12.0002 11.9997M12.0002 11.9997L19.8574 4.14258M12.0002 11.9997L4.14307 19.8569M12.0002 11.9997L4.14307 4.14258"
              stroke="#212022"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <validation-observer tag="form" ref="form" @submit.prevent="handleSubmit">
        <div class="mt-9 mb-8">
          <utils-input
            validationRules="required"
            inputName="Full Name"
            inputType="text"
            :isSubmitted="isSubmitted"
            v-model="data.name"
          />
        </div>
        <div>
          <base-button
            colorScheme="orange"
            class="ml-auto py-3 max-w-164 w-full"
          >
            <span class="font-normal text-sm"> Save </span>
          </base-button>
        </div>
      </validation-observer>
    </div>
  </t-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

import UtilsInput from "@/components/utils/UtilsInput.vue";
import BaseButton from "@/components/utils/Button.vue";
import { UPDATE_PROFILE } from "@/store/actions.type";

export default {
  name: "UpdateProfileModal",
  components: {
    ValidationObserver,
    "utils-input": UtilsInput,
    "base-button": BaseButton,
  },
  data() {
    return {
      isSubmitted: false,
      data: {
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user", "userError"]),
  },
  methods: {
    onBeforeOpen() {
      this.data.name = this.user ? this.user.name : "";
    },
    handleSubmit() {
      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        if (!valid) {
          return;
        }

        await this.$store.dispatch(UPDATE_PROFILE, this.data);

        if (this.userError) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: this.userError,
          });
        } else {
          this.$swal.fire({
            icon: "success",
            title: "Congratulation!",
            text: "Profile updated successfully",
          });
          this.$modal.hide("update-profile-modal");
        }
      });
    },
  },
};
</script>
