<template>
  <t-modal
    name="book-modal"
    noBody
    :focusOnOpen="false"
    @before-open="onBeforeOpen"
  >
    <div v-if="isBookLoading">Loading book...</div>
    <div
      v-else
      class="
        relative
        flex
        items-start
        h-full
        px-20
        pb-32
        overflow-y-auto
        rounded
        bg-white-900
        pt-14
      "
    >
      <!-- scrollbar-thin scrollbar-thumb-grey-700 scrollbar-track-grey-400 -->
      <button @click="closeModal" class="absolute top-5 right-5">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.8574 19.8569L12.0002 11.9997M12.0002 11.9997L19.8574 4.14258M12.0002 11.9997L4.14307 19.8569M12.0002 11.9997L4.14307 4.14258"
            stroke="#212022"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>

      <!-- <button
        class="absolute cursor-pointer left-8 top-72 hover:text-orange-900"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 21L7 12L16 3"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button> -->

      <!-- <button
        class="absolute cursor-pointer right-8 top-72 hover:text-orange-900"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 21L17 12L8 3"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button> -->

      <!-- image -->
      <custom-image
        :src="book.imageUrl"
        imgClass="flex-shrink-0 h-auto mr-16 shadow-md"
        :width="322"
      />
      <div class="flex-1">
        <!-- info -->
        <div class="mb-6">
          <h2 class="mb-2 text-3xl font-medium capitalize text-black-900">
            {{ book.title }}
          </h2>
          <h3
            v-for="(author, index) in book.author ? book.author : []"
            :key="index"
            class="
              inline
              text-lg
              font-medium
              uppercase
              cursor-pointer
              text-grey-700
            "
          >
            <router-link
              :to="{
                name: 'search',
                query: {
                  q: author.toLowerCase(),
                  fa: encodeURIComponent(JSON.stringify([author])),
                },
              }"
            >
              <span class="hover:underline">{{ author }}</span>
            </router-link>
            <span v-if="index + 1 < book.author.length">, </span>
          </h3>
        </div>
        <div class="flex items-start mb-8">
          <!-- details -->
          <ul class="flex-1 space-y-2 mr-11">
            <li class="text-base text-black-600">
              <h4 class="inline-block text-grey-700">ISBN:</h4>
              {{ book.isbn13 ? book.isbn13 : book.isbn10 }}
            </li>
            <li class="text-base text-black-600">
              <h4 class="inline-block mr-1 text-grey-700">Publisher:</h4>
              <div
                v-for="(publisher, index) in book.publishers
                  ? book.publishers
                  : []"
                :key="index"
                class="inline-block"
              >
                <router-link
                  v-if="publisher.handle"
                  :to="{
                    name: 'publisher',
                    params: { handle: publisher.handle },
                  }"
                >
                  <span class="underline cursor-pointer">{{
                    publisher.name
                  }}</span>
                </router-link>
                <span v-if="index + 1 < book.publishers.length">, </span>
              </div>
            </li>
            <li class="text-base text-black-600" v-if="book.publishingDate">
              <h4 class="inline-block text-grey-700">Pub. Date:</h4>
              {{ book.publishingDate }}
            </li>
            <li class="text-base text-black-600" v-if="book.format">
              <h4 class="inline-block text-grey-700">Format:</h4>
              {{ book.format }}
            </li>
            <li
              class="text-base text-black-600"
              v-if="book.editor && book.editor.length > 0"
            >
              <h4 class="inline-block mr-1 text-grey-700">Editor:</h4>
              <div
                v-for="(editor, index) in book.editor ? book.editor : []"
                :key="index"
                class="inline-block"
              >
                <span>{{ editor }}</span>
                <span v-if="index + 1 < book.editor.length">,&nbsp;</span>
              </div>
            </li>
            <li class="text-base text-black-600" v-if="book.edition">
              <h4 class="inline-block text-grey-700">Edition:</h4>
              {{ book.edition }}
            </li>
            <!-- <li class="text-base text-black-600">
              <h4 class="inline-block text-grey-700">Price:</h4>
              SGD 12.00
            </li> -->
            <li class="text-base text-black-600" v-if="book.pageCount">
              <h4 class="inline-block text-grey-700">Page count:</h4>
              {{ book.pageCount }}
            </li>
            <li class="text-base text-black-600" v-if="book.category">
              <h4 class="inline-block text-grey-700">Category:</h4>
              {{ book.category }}
            </li>
            <li class="text-base text-black-600" v-if="book.imprint">
              <h4 class="inline-block text-grey-700">Imprint:</h4>
              {{ book.imprint }}
            </li>
            <li
              class="text-base text-black-600"
              v-if="book.relatedISBNs && book.relatedISBNs.length > 0"
            >
              <h4 class="inline-block mr-1 text-grey-700">Related ISBN:</h4>
              <div
                v-for="(relatedISBN, index) in book.relatedISBNs
                  ? book.relatedISBNs
                  : []"
                :key="index"
                class="inline-block"
              >
                <span>{{ relatedISBN }}</span>
                <span v-if="index + 1 < book.relatedISBNs.length">,&nbsp;</span>
              </div>
            </li>
            <li class="text-base text-black-600" v-if="book.targetAudience">
              <h4 class="inline-block text-grey-700">Target Audience:</h4>
              {{ book.targetAudience }}
            </li>
          </ul>
          <!-- price -->
          <div
            v-if="isAuthenticated"
            class="
              flex flex-col
              items-center
              self-center
              flex-shrink-0
              px-16
              py-8
              rounded
              bg-grey-400
            "
          >
            <span
              class="
                before:empty-content
                before:w-1.5
                before:h-1.5
                before:rounded-full
                before:mr-1.5
                text-base
                flex
                items-center
                mb-2
              "
              :class="[
                book.available
                  ? 'before:bg-green-700 text-green-700'
                  : 'before:bg-red-600 text-red-600',
              ]"
            >
              {{ book.available ? "Available" : "Not available" }}
            </span>
            <span
              v-if="book.price && book.currencyCode"
              class="mb-4 text-4xl font-medium uppercase text-black-900"
            >
              {{ book.currencyCode }} {{ book.price.toFixed(2) }}
            </span>
            <span
              v-else
              class="mb-4 text-4xl font-medium uppercase text-black-900"
            >
              SGD -
            </span>
            <base-button
              :big="true"
              :active="book.shortlistIds && book.shortlistIds.length > 0"
              colorScheme="orange"
              class="mb-3"
              @click.native="handleShortlist"
            >
              <svg
                v-if="book.shortlistIds && book.shortlistIds.length > 0"
                class="mr-2"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <svg
                v-else
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.5523 9.5043L12 4.3331L9.44772 9.5043L3.74112 10.3336L7.87038 14.3588L6.89574 20.0425L12.0001 17.3589L17.1041 20.0424L16.1292 14.3588L20.2588 10.3336L14.5523 9.5043ZM1.43235 10.1777L6.25868 14.8825L5.1195 21.5257C5.07891 21.7622 5.17615 22.0011 5.37026 22.1422C5.48007 22.222 5.61013 22.2626 5.74082 22.2626C5.84118 22.2626 5.94187 22.2387 6.03407 22.1902L12 19.0536L17.9658 22.1901C18.1782 22.3018 18.4355 22.2832 18.6296 22.1422C18.8237 22.0011 18.921 21.7621 18.8805 21.5256L17.7409 14.8825L22.5678 10.1777C22.7396 10.0103 22.8015 9.75978 22.7273 9.53159C22.6531 9.30336 22.4559 9.13703 22.2185 9.10257L15.5484 8.13329L12.5653 2.08915C12.4591 1.87399 12.24 1.73779 12.0001 1.73779C11.7601 1.73779 11.541 1.87399 11.4348 2.08915L8.45165 8.13329L1.78165 9.10257C1.54426 9.13703 1.34695 9.30336 1.27283 9.53154C1.19865 9.75978 1.26051 10.0103 1.43235 10.1777Z"
                  fill="currentColor"
                />
              </svg>

              To Shortlist
            </base-button>
            <base-button
              v-if="user.type === 'admin' || user.type === 'reseller'"
              @click.native="exportToPdf"
              :big="true"
            >
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.4288 7V19.7142C19.4288 20.8188 18.5334 21.7142 17.4288 21.7142H7.14307C6.0385 21.7142 5.14307 20.8188 5.14307 19.7142V4.28564C5.14307 3.18107 6.0385 2.28564 7.14307 2.28564H15.0002M19.4288 7L15.0002 2.28564M19.4288 7H16.0002C15.4479 7 15.0002 6.55228 15.0002 6V2.28564M12.2859 8.85707V15.9999M12.2859 15.9999L15.1431 13.4285M12.2859 15.9999L9.14307 13.4285"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              Export to PDF
            </base-button>
          </div>
        </div>

        <!-- description -->
        <div class="mb-8" v-if="book.description">
          <h5 class="mb-3 text-lg font-medium uppercase text-grey-700">
            Description
          </h5>
          <div
            class="space-y-4 text-base text-black-600"
            v-html="handleNewLine(book.description)"
          ></div>
        </div>
        <!-- endorsement -->
        <div class="mb-8" v-if="book.endorsement">
          <h5 class="mb-3 text-lg font-medium uppercase text-grey-700">
            Endorsement
          </h5>
          <div
            class="space-y-4 text-base text-black-600"
            v-html="book.endorsement"
          ></div>
        </div>
        <!-- promotional headline -->
        <div class="mb-8" v-if="book.promotionalHeadline">
          <h5 class="mb-3 text-lg font-medium uppercase text-grey-700">
            Promotional headline
          </h5>
          <div
            class="space-y-4 text-base text-black-600"
            v-html="book.promotionalHeadline"
          ></div>
        </div>
        <!-- Official Recommendation -->
        <div class="mb-8" v-if="book.officialRecommendation">
          <h5 class="mb-3 text-lg font-medium uppercase text-grey-700">
            Official Recommendation
          </h5>
          <div
            class="space-y-4 text-base text-black-600"
            v-html="book.officialRecommendation"
          ></div>
        </div>
        <!-- Awards -->
        <div class="mb-8" v-if="book.awards && book.awards.length > 0">
          <h5 class="mb-3 text-lg font-medium uppercase text-grey-700">
            Awards
          </h5>
          <div
            class="text-base text-black-600"
            v-for="(award, index) in book.awards"
            :key="index"
          >
            {{ award }}
          </div>
        </div>
        <!-- Publisher contacts -->
        <template
          v-if="
            (user.type === 'admin' || user.type === 'reseller') &&
            book.publishers &&
            book.publishers.length > 0
          "
        >
          <div
            class="mb-8"
            v-for="(publisher, index) in book.publishers"
            :key="index"
          >
            <h5 class="mb-4 text-lg font-medium uppercase text-grey-700">
              Distributor/publisher contacts
              <span v-if="index > 0">{{ index + 1 }}</span>
            </h5>
            <div class="mb-4" v-if="publisher.addressLine1">
              <h6 class="mb-2 text-base font-medium text-black-900">
                Mailing Address
              </h6>
              <div class="text-base text-black-600">
                <span v-if="publisher.name"> {{ publisher.name }}<br /> </span>
                <span v-if="publisher.addressLine1">
                  {{ publisher.addressLine1 }} <br />
                </span>
                <span v-if="publisher.addressLine2">
                  {{ publisher.addressLine2 }} <br />
                </span>
                <span v-if="publisher.country || publisher.postalCode">
                  {{ publisher.country }} {{ publisher.postalCode }}
                </span>
              </div>
            </div>
            <div class="mb-4" v-if="publisher.organizationPhone">
              <h6 class="mb-2 text-base font-medium text-black-900">
                Telephone
              </h6>
              <div class="text-base text-black-600">
                <a :href="`tel:${publisher.organizationPhone}`">
                  {{ publisher.organizationPhone }}
                </a>
              </div>
            </div>
            <div class="mb-4" v-if="publisher.organizationFax">
              <h6 class="mb-2 text-base font-medium text-black-900">Fax</h6>
              <div class="text-base text-black-600">
                <a :href="`tel:${publisher.organizationFax}`">
                  {{ publisher.organizationFax }}
                </a>
              </div>
            </div>
            <div class="mb-4" v-if="publisher.organizationEmail">
              <h6 class="mb-2 text-base font-medium text-black-900">E-mail</h6>
              <div class="text-base underline text-black-600">
                <a
                  class="underline hover:text-orange-900"
                  :href="`mailto:${publisher.organizationEmail}`"
                  >{{ publisher.organizationEmail }}
                </a>
              </div>
            </div>
            <div class="mb-4" v-if="publisher.organizationWebsite">
              <h6 class="mb-2 text-base font-medium text-black-900">Website</h6>
              <div class="text-base underline text-black-600">
                <a
                  class="underline hover:text-orange-900"
                  :href="publisher.organizationWebsite"
                  >{{ publisher.organizationWebsite }}
                </a>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </t-modal>
</template>

<script>
import { mapGetters } from "vuex";

import BaseButton from "@/components/utils/Button.vue";
import { GET_BOOK, LOAD_SELECTED_SHORTLIST } from "@/store/actions.type";
import { BookService } from "@/common/api.service";
import CustomImage from "@/components/utils/CustomImage.vue";

export default {
  components: {
    "base-button": BaseButton,
    "custom-image": CustomImage,
  },
  data() {
    return {
      pdfFile: null,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "user",
      "book",
      "bookBasePage",
      "isBookLoading",
    ]),
  },
  methods: {
    onBeforeOpen({ params }) {
      this.$store.dispatch(GET_BOOK, params.handle);
    },
    closeModal() {
      this.$router.push(this.bookBasePage);
    },
    async handleShortlist() {
      if (this.isAuthenticated) {
        await this.$store.dispatch(LOAD_SELECTED_SHORTLIST, {
          shortlistIds: [...this.book.shortlistIds],
          bookId: this.book.id,
          catalogId: null,
        });
        this.$modal.show("shortlist-modal", {
          component: "add-shortlist-modal",
        });
      } else {
        // redirect to login page if not yet authenticated
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.path },
        });
      }
    },
    async exportToPdf() {
      const { data } = await BookService.getPdf(this.book.id);
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
    handleNewLine(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
  },
};
</script>
