<template>
  <div class="pt-20">
    <base-header />
    <router-view class="flex-grow" />
    <base-footer :class="{ 'pb-14': hasStickyFooter }" />
    <search-modal />
    <update-profile-modal />
    <change-password-modal />
    <book-detail-modal />
    <shortlist-modal />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/base/Header.vue";
import Footer from "@/components/base/Footer.vue";
import ShortlistModal from "@/components/modals/ShortlistModal.vue";
import SearchModal from "@/components/modals/SearchModal.vue";
import BookDetailModal from "@/components/modals/BookDetail.vue";
import UpdateProfileModal from "@/components/modals/UpdateProfileModal.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal.vue";

export default {
  components: {
    "base-header": Header,
    "base-footer": Footer,
    "search-modal": SearchModal,
    "update-profile-modal": UpdateProfileModal,
    "change-password-modal": ChangePasswordModal,
    "book-detail-modal": BookDetailModal,
    "shortlist-modal": ShortlistModal,
  },
  computed: {
    hasStickyFooter() {
      return this.$route.meta.stickyFooter;
    },
  },
};
</script>
